/**
 * HTTP 오류
 */
// eslint-disable-next-line import/prefer-default-export
export class HttpError extends Error {
  status: any;

  constructor(status, message, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    // Custom debugging information
    this.status = status;
    this.message = message;
    this.name = 'HttpError';
  }
}

export interface IAxiosErrorResponseData {
  c?: number;
  code?: number;
  m?: string;
  message?: string;
  ts?: number;
  timestamp?: number;
}
