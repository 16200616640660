import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';

import { showLoading, hideLoading } from '~/utils/postMessage';

import fetcher from '~/api/lib/fetcher';
import { HttpError } from '~/types/Error';
import { convertError } from '~/utils/converter';
import { getAxiosErrorInfo } from '~/utils/common';
import { STORE_PRODUCT_URL_PATH } from '~/components/store/storeMessage';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const FETCH_VALID_VISUALS_FAIL = 'FETCH_VALID_VISUALS_FAIL';
const FETCH_VALID_PRODUCTS_FAIL = 'FETCH_VALID_PRODUCTS_FAIL';
const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
const SET_LIST_VIEW_TYPE = 'SET_LIST_VIEW_TYPE';
const FETCH_STORE_MAIN_INIT = 'FETCH_STORE_MAIN_INIT';
const FETCH_CATEGORY_LIST_SUCCESS = 'FETCH_CATEGORY_LIST_SUCCESS';
const FETCH_CATEGORY_LIST_FAILURE = 'FETCH_CATEGORY_LIST_FAILURE';
const FETCH_STORE_MAIN_INIT_FAILURE = 'FETCH_STORE_MAIN_INIT_FAILURE';
const FETCH_PRODUCTS_LIST = 'FETCH_PRODUCTS_LIST';
const FETCH_PRODUCTS_LIST_SUCCESS = 'FETCH_PRODUCTS_LIST_SUCCESS';
const FETCH_STORE_VISUAL_LIST = 'FETCH_STORE_VISUAL_LIST';
const FETCH_VISUALS_LIST_SUCCESS = 'FETCH_VISUALS_LIST_SUCCESS';
const FETCH_PRODUCTS_LIST_FAILURE = 'FETCH_PRODUCTS_LIST_FAILURE';
const FETCH_VISUALS_LIST_FAILURE = 'FETCH_VISUALS_LIST_FAILURE';
const SET_PAGING = 'SET_PAGING';

const fetchValidVisualsFail = createAction(FETCH_VALID_VISUALS_FAIL);
const fetchValidProductsFail = createAction(FETCH_VALID_PRODUCTS_FAIL);
const setActiveCategory = createAction(SET_ACTIVE_CATEGORY);
const setListViewType = createAction(SET_LIST_VIEW_TYPE);
const fetchtStoreMainInit = createAction(FETCH_STORE_MAIN_INIT);
const fetchCategoryListSuccess = createAction(FETCH_CATEGORY_LIST_SUCCESS);
const fetchCategoryListFailure = createAction(FETCH_CATEGORY_LIST_FAILURE);
const fetchtStoreMainInitFailure = createAction(FETCH_STORE_MAIN_INIT_FAILURE);
const fetchProductsList = createAction(FETCH_PRODUCTS_LIST);
const fetchProductsListSuccess = createAction(FETCH_PRODUCTS_LIST_SUCCESS);
const fetchStoreVisualList = createAction(FETCH_STORE_VISUAL_LIST);
const fetchVisualsListSuccess = createAction(FETCH_VISUALS_LIST_SUCCESS);
const fetchProductsListFailure = createAction(FETCH_PRODUCTS_LIST_FAILURE);
const fetchVisualsListFailure = createAction(FETCH_VISUALS_LIST_FAILURE);
const setPaging = createAction(SET_PAGING);

export {
  fetchStoreVisualList,
  fetchValidVisualsFail,
  fetchValidProductsFail,
  setActiveCategory,
  setListViewType,
  fetchtStoreMainInit,
  fetchProductsList,
};

const initialState = {
  pending: false,
  visuals: [],
  data: {
    activeCategory: 0,
    listViewType: 'list',
    category: [],
    products: [],
    paging: {
      page: 0,
      size: 20,
      totalElements: null,
      totalPages: null,
      isLastPage: false,
      isLoading: false,
    },
  },
  error: null,
  errorInfo: null,
};

export const reducer = handleActions(
  {
    [FETCH_VALID_VISUALS_FAIL]: (state, { payload: { productId } }: any) =>
      produce(state, (draft) => {
        draft.visuals = draft.visuals.filter(
          (item: any) => item.tr !== `${STORE_PRODUCT_URL_PATH}${productId}`
        );
      }),
    [FETCH_VALID_PRODUCTS_FAIL]: (state, { payload: { productId } }: any) =>
      produce(state, (draft) => {
        draft.data.products = draft.data.products.filter((item: any) => item.id !== productId);
      }),
    [SET_PAGING]: (state, { payload }: any) =>
      produce(state, (draft) => {
        draft.data.paging = {
          ...draft.data.paging,
          ...payload,
        };
      }),
    [SET_ACTIVE_CATEGORY]: (state, { payload: activeId }: any) =>
      produce(state, (draft) => {
        draft.data.activeCategory = activeId;
        draft.data.paging = {
          ...draft.data.paging,
          page: 0,
          isLastPage: false,
        };
      }),
    [SET_LIST_VIEW_TYPE]: (state, { payload: type }: any) =>
      produce(state, (draft) => {
        draft.data.listViewType = type;
      }),
    [FETCH_STORE_MAIN_INIT]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data = initialState.data;
        draft.error = null;
      }),
    [FETCH_CATEGORY_LIST_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        draft.pending = false;
        draft.data.category = d;
        draft.error = null;
        draft.errorInfo = null;
      }),
    [FETCH_PRODUCTS_LIST]: (state, { payload: page }: any) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
        draft.data.paging = {
          ...draft.data.paging,
          page: page || 0,
          isLoading: true,
        };
        draft.errorInfo = null;
      }),
    [FETCH_STORE_VISUAL_LIST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.visuals = [];
        draft.errorInfo = null;
      }),
    [FETCH_VISUALS_LIST_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        const LSTORE = d.filter((item) => item.c === 'LSTORE');

        if (LSTORE.length === 0) {
          return;
        }

        draft.pending = false;
        draft.visuals = d.filter((item) => item.c === 'LSTORE')[0].ir;
        draft.errorInfo = null;
      }),
    [FETCH_PRODUCTS_LIST_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        const isListRefresh = d.page === 0;

        draft.pending = false;
        draft.data.products = isListRefresh ? d.content : draft.data.products.concat(d.content);
        draft.data.paging = {
          page: d.page,
          size: d.size,
          totalElements: d.totalElements,
          totalPages: d.totalPages,
          isLastPage: d.page + 1 === d.totalPages,
          isLoading: false,
        };
        draft.error = null;
        draft.errorInfo = null;
      }),
    [FETCH_PRODUCTS_LIST_FAILURE]: (state, { payload: { error, errorInfo } }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
        draft.errorInfo = errorInfo;
      }),
    [FETCH_STORE_MAIN_INIT_FAILURE]: (state, { payload: { errorInfo } }) =>
      produce(state, (draft) => {
        draft.pending = false;
        // draft.error = convertError(error);
        draft.errorInfo = errorInfo;
      }),
    [FETCH_CATEGORY_LIST_FAILURE]: (state, { payload: { error, errorInfo } }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
        draft.errorInfo = errorInfo;
      }),
    [FETCH_VISUALS_LIST_FAILURE]: (state, { payload: { error, errorInfo } }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
        draft.errorInfo = errorInfo;
      }),
  },
  initialState
);

function* watchFetchVisualListsaga({ payload: eupMyeonDongRiCode }) {
  const visualsUrl = eupMyeonDongRiCode
    ? `${V2_API}/v2/cms/active?eup_myeon_dong_ri_code=${eupMyeonDongRiCode}`
    : `${V2_API}/v2/cms`;

  try {
    const resVisuals = yield call(fetcher.get, visualsUrl);

    yield put(fetchVisualsListSuccess({ ...resVisuals.data }));
  } catch (error) {
    const errorInfo = getAxiosErrorInfo(error);

    yield put(
      fetchVisualsListFailure({
        error,
        errorInfo,
      })
    );
    throw new Error(`GET ${visualsUrl} Failed`);
  }
}

function* watchFetchProductsListSaga(isInit, eupMyeonDongRiCode = undefined) {
  if (isInit === true) {
    yield put(
      setPaging({
        page: 0,
        isLastPage: false,
      })
    );
  }

  const { activeCategory, paging } = yield select((state) => state.store.main.data);
  const visuals = yield select((state) => state.store.main.visuals);

  const { isLastPage } = paging;

  if (isInit !== true && isLastPage) {
    return;
  }

  showLoading();

  const query = `size=${paging.size}&page=${isInit === true ? 0 : paging.page}${
    activeCategory !== 0 ? `&categoryId=${activeCategory}` : ''
  }`;
  const url = `${V2_API}/v2/products?${query}`;

  try {
    if (paging.page === 0 && activeCategory === 0 && visuals.length === 0) {
      yield call(watchFetchVisualListsaga, { payload: eupMyeonDongRiCode });
    }
    const result = yield call(fetcher.get, url);

    yield put(fetchProductsListSuccess({ ...result.data }));
  } catch (error) {
    const errorInfo = getAxiosErrorInfo(error);

    yield put(
      fetchProductsListFailure({
        error,
        errorInfo,
      })
    );
    throw new Error(`GET ${url} Failed`);
  } finally {
    yield delay(250);
    hideLoading();
  }
}

function* watchFetchCategoryListSaga() {
  const categoryUrl = `${V2_API}/v2/products/categories`;

  try {
    const categoryResult = yield call(fetcher.get, categoryUrl);
    yield put(fetchCategoryListSuccess({ ...categoryResult.data }));

    return categoryResult;
  } catch (error) {
    const errorInfo = getAxiosErrorInfo(error);

    yield put(
      fetchCategoryListFailure({
        error,
        errorInfo,
      })
    );

    throw new Error(`GET ${categoryUrl} Failed`);
  }
}

function* watchFetchStoreMainInitSaga({ payload }) {
  showLoading();

  try {
    const { eupMyeonDongRiCode, activeId } = payload;

    yield put(setActiveCategory(activeId));
    yield call(watchFetchCategoryListSaga);
    yield call(watchFetchProductsListSaga, true, eupMyeonDongRiCode);
  } catch (error) {
    const errorInfo = getAxiosErrorInfo(error);
    yield put(fetchtStoreMainInitFailure({ errorInfo }));
  }
}

export const sagas = [
  takeLatest<any>(FETCH_STORE_VISUAL_LIST, watchFetchVisualListsaga),
  takeLatest<any>(FETCH_PRODUCTS_LIST, watchFetchProductsListSaga),
  takeLatest<any>(FETCH_STORE_MAIN_INIT, watchFetchStoreMainInitSaga),
];
