import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';

import MESSAGES from '~/components/store/storeMessage';
import {
  showLoading,
  hideLoading,
  showAlert,
  showToast,
  completeAlertCloseAll,
  brazeEvent,
  fullPagePresent,
  executeWeb2App,
} from '~/utils/postMessage';

import fetcher from '~/api/lib/fetcher';
import { HttpError } from '~/types/Error';
import { convertError } from '~/utils/converter';

import { getV2AuthHeaders, getAccmulatePoint, getNewAppVersionYn } from '~/utils/common';
import { IProductSelectedOptionListItem } from '~/components/store/types';

import {
  cartCountFetchRequest,
  watchPossibleOrderFetchSaga,
} from '~/redux/modules/store/storeCommon';
import { fetchValidVisualsFail, fetchValidProductsFail } from '~/redux/modules/store/storeMain';
import CONSTANT from '~/utils/constant';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const FETCH_VALID_PRODUCT = 'FETCH_VALID_PRODUCT';
const FETCH_VALID_RERATION_FAIL = 'FETCH_VALID_RERATION_FAIL';
const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
const FETCH_PRODUCT_DETAIL_SUCCESS = 'FETCH_PRODUCT_DETAIL_SUCCESS';
const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
const FETCH_PRODUCT_FAILURE_CLEAR = 'FETCH_PRODUCT_FAILURE_CLEAR';
const SET_PRODUCT_DETAIL_BUY_POSSIBLE_YN = 'SET_PRODUCT_DETAIL_BUY_POSSIBLE_YN';
const PRODUCT_OPTION_SELECT = 'PRODUCT_OPTION_SELECT';
const PRODUCT_OPTION_SELECT_CLEAR = 'PRODUCT_OPTION_SELECT_CLEAR';
const PRODUCT_OPTION_DELETE = 'PRODUCT_OPTION_DELETE';
const PRODUCT_OPTION_DELETE_SUCCESS = 'PRODUCT_OPTION_DELETE_SUCCESS';
const PRODUCT_COUNT_UPDATE = 'PRODUCT_COUNT_UPDATE';
const PRODUCT_ADD_CART_REQUEST = 'PRODUCT_ADD_CART_REQUEST';
const PRODUCT_ADD_CART_SUCCESS = 'PRODUCT_ADD_CART_SUCCESS';
const PRODUCT_BUY_NOW_REQUEST = 'PRODUCT_BUY_NOW_REQUEST';
const PRODUCT_BUY_NOW_SUCCESS = 'PRODUCT_BUY_NOW_SUCCESS';
const PRODUCT_DATA_CLEAR = 'PRODUCT_DATA_CLEAR';

const fetchValidProduct = createAction(FETCH_VALID_PRODUCT);
const fetchValidRelationFail = createAction(FETCH_VALID_RERATION_FAIL);
const fetchProductDetail = createAction(FETCH_PRODUCT_DETAIL);
const fetchProdcutDetailSuccess = createAction(FETCH_PRODUCT_DETAIL_SUCCESS);
const fetchProdcutFailure = createAction(FETCH_PRODUCT_FAILURE);
const fetchProdcutFailureClear = createAction(FETCH_PRODUCT_FAILURE_CLEAR);
const setProductDetailBuyPossibleYn = createAction(SET_PRODUCT_DETAIL_BUY_POSSIBLE_YN);
const productOptionSelect = createAction(PRODUCT_OPTION_SELECT);
const productOptionSelectClear = createAction(PRODUCT_OPTION_SELECT_CLEAR);
const productOptionDelete = createAction(PRODUCT_OPTION_DELETE);
const productOptionDeleteSuccess = createAction(PRODUCT_OPTION_DELETE_SUCCESS);
const productCountUpdate = createAction(PRODUCT_COUNT_UPDATE);
const productAddCartRequest = createAction(PRODUCT_ADD_CART_REQUEST);
const productAddCartSuccess = createAction(PRODUCT_ADD_CART_SUCCESS);
const productBuyNowRequest = createAction(PRODUCT_BUY_NOW_REQUEST);
const productBuyNowSuccess = createAction(PRODUCT_BUY_NOW_SUCCESS);
const productDataClear = createAction(PRODUCT_DATA_CLEAR);

export {
  fetchValidProduct,
  fetchValidRelationFail,
  fetchProductDetail,
  setProductDetailBuyPossibleYn,
  productOptionSelect,
  productOptionSelectClear,
  productOptionDelete,
  productCountUpdate,
  productAddCartRequest,
  productBuyNowRequest,
  productDataClear,
  fetchProdcutFailureClear,
};

const getNewSkuGroups = (skuGroups) => {
  return (
    skuGroups &&
    skuGroups.map((skuGroup) => {
      const newSkus = skuGroup.skus.map((sku) => {
        return {
          ...sku,
          searchAttributeIds:
            sku.attributeIds && sku.attributeIds.slice(0, sku.attributeIds.length - 1),
        };
      });

      return {
        ...skuGroup,
        skus: newSkus,
      };
    })
  );
};

interface IInitialState {
  pending: boolean;
  data: any;
  selectedItems: {
    totalQuantity: number;
    totalOriginalPrice: number;
    totalDiscountPrice: number;
    totalSellPrice: number;
    totalAccumulatePoint: number;
    list: IProductSelectedOptionListItem[];
  };
  error: any;
}

const initialState: IInitialState = {
  pending: false,
  data: {},
  selectedItems: {
    totalQuantity: 0,
    totalOriginalPrice: 0,
    totalDiscountPrice: 0,
    totalSellPrice: 0,
    totalAccumulatePoint: 0,
    list: [],
  },
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_VALID_PRODUCT]: (state) =>
      produce(state, (draft) => {
        draft.error = null;
      }),
    [FETCH_VALID_RERATION_FAIL]: (state, { payload: { productId } }: any) =>
      produce(state, (draft) => {
        draft.data.relation.relationProducts = draft.data.relation.relationProducts.filter(
          (item) => item.productId !== productId
        );
      }),
    [FETCH_PRODUCT_DETAIL]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data = initialState.data;
        draft.error = null;
      }),
    [FETCH_PRODUCT_DETAIL_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        draft.pending = false;
        draft.data = {
          ...d,
          productSkuGroups: getNewSkuGroups(d.productSkuGroups),
        };
        draft.error = null;
      }),
    [FETCH_PRODUCT_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
    [FETCH_PRODUCT_FAILURE_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.error = null;
      }),
    [SET_PRODUCT_DETAIL_BUY_POSSIBLE_YN]: (state, { payload: { buyPossibleYn } }: any) =>
      produce(state, (draft) => {
        draft.data.buyPossibleYn = buyPossibleYn;
      }),
    [PRODUCT_OPTION_SELECT]: (state, { payload }: any) =>
      produce(state, (draft) => {
        const { quantity, productOriginalPrice, productAccumulateRate, skus } = payload;
        const { totalQuantity, totalSellPrice } = draft.selectedItems;

        const newTotalQuantity = totalQuantity + quantity;
        const skusSellPrice = skus.reduce((acc, current) => {
          return acc + current.sellPrice;
        }, 0);
        const newTotalSellPrice = totalSellPrice + skusSellPrice * quantity;

        const sumTotalOriginalPrice = productOriginalPrice * newTotalQuantity;
        const newTotalOriginalPrice = Math.max(newTotalSellPrice, sumTotalOriginalPrice);
        const newTotalAccumulatePoint = getAccmulatePoint(newTotalSellPrice, productAccumulateRate);

        const existSeletedItem = state.selectedItems.list.filter(
          (item) => item.searchId === payload.searchId
        );

        if (existSeletedItem.length === 0) {
          draft.selectedItems = {
            totalQuantity: newTotalQuantity,
            totalOriginalPrice: newTotalOriginalPrice,
            totalAccumulatePoint: newTotalAccumulatePoint,
            totalDiscountPrice: newTotalOriginalPrice - newTotalSellPrice,
            totalSellPrice: newTotalSellPrice,
            list: [...draft.selectedItems.list, payload],
          };
        }
      }),
    [PRODUCT_OPTION_SELECT_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.selectedItems = initialState.selectedItems;
      }),
    [PRODUCT_OPTION_DELETE]: (state) => state,
    [PRODUCT_OPTION_DELETE_SUCCESS]: (state, { payload: { selectedItemIndex } }: any) =>
      produce(state, (draft) => {
        draft.selectedItems.list = draft.selectedItems.list.filter(
          (_item, itemIndex) => itemIndex !== selectedItemIndex
        );
        draft.error = null;
      }),
    [PRODUCT_COUNT_UPDATE]: (state, { payload: { selectedItemIndex, newQuantity } }: any) =>
      produce(state, (draft) => {
        const targetItem = state.selectedItems.list[selectedItemIndex];
        const { totalQuantity, totalOriginalPrice, totalSellPrice } = draft.selectedItems;

        const diffQuantity = newQuantity - targetItem.quantity;
        const newTotalQuantity = totalQuantity + diffQuantity;
        const skusSellPrice = targetItem.skus.reduce((acc, current) => {
          return acc + current.sellPrice;
        }, 0);
        const newTotalSellPrice = totalSellPrice + skusSellPrice * diffQuantity;

        const sumTotalOriginalPrice =
          totalOriginalPrice + targetItem.productOriginalPrice * diffQuantity;
        const newTotalOriginalPrice = Math.max(newTotalSellPrice, sumTotalOriginalPrice);

        const newTotalDiscountPrice = newTotalOriginalPrice - newTotalSellPrice;
        const newTotalAccumulatePoint = getAccmulatePoint(
          newTotalSellPrice,
          targetItem.productAccumulateRate
        );
        draft.selectedItems = {
          ...draft.selectedItems,
          totalQuantity: newTotalQuantity,
          totalOriginalPrice: newTotalOriginalPrice,
          totalDiscountPrice: newTotalDiscountPrice,
          totalSellPrice: newTotalSellPrice,
          totalAccumulatePoint: newTotalAccumulatePoint,
        };
        draft.selectedItems.list[selectedItemIndex].quantity = newQuantity;
      }),
    [PRODUCT_ADD_CART_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
      }),
    [PRODUCT_ADD_CART_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.selectedItems = initialState.selectedItems;
        draft.error = null;
      }),
    [PRODUCT_BUY_NOW_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
      }),
    [PRODUCT_BUY_NOW_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.selectedItems = initialState.selectedItems;
        draft.error = null;
      }),
    [PRODUCT_DATA_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.data = initialState.data;
        draft.selectedItems = initialState.selectedItems;
      }),
  },
  initialState
);

// 상품 유효성 체크
function* watchFetchValidProductSaga(action) {
  showLoading();

  const {
    productId,
    listType,
    successCallback,
  }: {
    productId: number;
    listType: 'products' | 'visuals' | 'relation';
    successCallback: () => void;
  } = action.payload;

  const { headers } = yield select((state) => state.http);

  try {
    const fetchHeaders =
      headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] === undefined
        ? undefined
        : {
            headers: {
              ...getV2AuthHeaders(headers),
            },
          };

    const validProductUrl = `${V2_API}/v2/products/validation?productId=${productId}`;

    yield call(fetcher.get, validProductUrl, fetchHeaders);

    if (successCallback !== undefined) {
      successCallback();
    }
  } catch (error) {
    const { c, m } = (error as any).response.data;
    if (c === 30008 && listType !== undefined) {
      yield call(executeWeb2App, {
        command: 'showAlert',
        values: {
          title: MESSAGES.ORDER.SELL_EXPIRATION_TITLE,
          message: MESSAGES.ORDER.SELL_EXPIRATION,
        },
      });

      const reqProductId = {
        productId: Number(productId),
      };

      if (listType === 'products') {
        yield put(fetchValidProductsFail(reqProductId));
      } else if (listType === 'visuals') {
        yield put(fetchValidVisualsFail(reqProductId));
      } else if (listType === 'relation') {
        yield put(fetchValidRelationFail(reqProductId));
      }
    } else {
      yield call(showAlert, '서버 오류', m);
    }
  } finally {
    yield delay(200);
    hideLoading();
  }
}

// 상품 상세 정보 가져오기
function* watchFetchProductDetailSaga(action) {
  showLoading();

  const { productId, isScrollTo } = action.payload;
  const { headers } = yield select((state) => state.http);

  try {
    const fetchHeaders =
      headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] === undefined
        ? undefined
        : {
            headers: {
              ...getV2AuthHeaders(headers),
            },
          };

    const url = `${V2_API}/v2/products/${productId}`;
    const result = yield call(fetcher.get, url, fetchHeaders);

    yield put(fetchProdcutDetailSuccess({ ...result.data }));
  } catch (error) {
    yield put(fetchProdcutFailure(error));
  } finally {
    yield delay(200);
    hideLoading();

    if (isScrollTo === true) {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 100);
    }
  }
}

// 선택된 옵션 삭제
function* watchProductOptionDeleteSaga(action) {
  const { selectedItemIndex } = action.payload;

  try {
    yield put(
      productCountUpdate({
        selectedItemIndex,
        newQuantity: 0,
      })
    );
    yield put(
      productOptionDeleteSuccess({
        selectedItemIndex,
      })
    );
  } catch (error) {
    console.log('[watchProductOptionDelete] ERROR');
  }
}

// 선택된 상품 장바구니에 추가
function* watchFetchProductAddCartSaga(action) {
  showLoading();

  const { headers } = yield select((state) => state.http);
  const currentProduct = yield select((state) => state.store.product.data);
  const { list, totalSellPrice } = yield select((state) => state.store.product.selectedItems);
  const Window = window as any;

  const { productId, isCallMain, callback } = action.payload;

  const reqProducts = list.map((item) => ({
    id: item.productId,
    quantity: item.quantity,
    skus: item.skus.map((sku) => ({
      productSkuGroupId: sku.productSkuGroupId,
      skuId: sku.skuId,
    })),
  }));

  try {
    const url = `${V2_API}/v2/products/basket`;
    yield call(
      fetcher.post,
      url,
      {
        products: reqProducts,
      },
      {
        headers: {
          ...getV2AuthHeaders(headers),
        },
      }
    );

    yield put(productAddCartSuccess());
    yield put(cartCountFetchRequest());

    yield* watchFetchProductDetailSaga({
      payload: {
        productId: currentProduct.id,
      },
    });

    const isNewAppVersion =
      getNewAppVersionYn({
        currentVersion: headers['app-version'],
        newVersion: '1.6.0',
      }) === 'Y';

    if (isNewAppVersion) {
      if (Window.addCartToastCallback === undefined) {
        Window.addCartToastCallback = () => {
          const { origin } = Window.location;
          fullPagePresent(`cart`, `${origin}/store/cart`);
        };
      }

      executeWeb2App({
        command: 'btnToast',
        values: {
          message: MESSAGES.PRODUCT.ADD_CART_SUCCESS,
          button_name: '보러가기',
          button_callback: 'addCartToastCallback',
        },
      });
    } else {
      showToast(MESSAGES.PRODUCT.ADD_CART_SUCCESS, 'Y');
    }

    brazeEvent({
      eventName: 'item_added_to_cart',
      eventValue: {
        item_id: String(currentProduct.id),
        item_name: currentProduct.name,
        item_category: currentProduct.categoryName,
        item_price: `${totalSellPrice}`,
        item_tag: currentProduct.tags.join(','),
        item_image_url: currentProduct.representImgUrls[0],
        item_brand_name: currentProduct.brandName,
      },
    });
  } catch (error) {
    const { m } = (error as any).response.data;

    if (m === MESSAGES.ORDER.SELL_EXPIRATION) {
      yield call(executeWeb2App, {
        command: 'showAlert',
        values: {
          title: MESSAGES.ORDER.SELL_EXPIRATION_TITLE,
          message: MESSAGES.ORDER.SELL_EXPIRATION,
        },
      });

      const reqProductId = {
        productId: Number(productId),
      };

      if (isCallMain) {
        yield put(fetchValidProductsFail(reqProductId));
      } else {
        yield put(
          setProductDetailBuyPossibleYn({
            buyPossibleYn: 'N',
          })
        );
      }
    } else {
      yield put(fetchProdcutFailure(error));
    }
  } finally {
    hideLoading();

    if (callback !== undefined) {
      callback();
    }
  }
}

// 선택된 상품 바로구매
function* watchFetchProductBuyNowSaga() {
  showLoading();

  const { headers } = yield select((state) => state.http);
  const storeState = yield select((state) => state.store);
  const { selectedItems, data: currentProduct } = storeState.product;
  const { washId } = storeState.common;
  const { totalSellPrice, totalQuantity, list } = selectedItems;

  const isNewAppVersion =
    getNewAppVersionYn({
      currentVersion: headers['app-version'],
      newVersion: '1.6.0',
    }) === 'Y';

  const reqProducts = list.map((item) => ({
    id: item.productId,
    quantity: item.quantity,
    skus: item.skus.map((sku) => ({
      productSkuGroupId: sku.productSkuGroupId,
      skuId: sku.skuId,
    })),
  }));

  try {
    yield* watchPossibleOrderFetchSaga();
    const { isOrderAvailable } = yield select((state) => state.store.common);

    if (!isOrderAvailable) {
      showToast(MESSAGES.PRODUCT.BUY_TIMEOUT);
      return;
    }

    const url = `${V2_API}/v2/products/order`;
    yield call(
      fetcher.post,
      url,
      {
        products: reqProducts,
        userTotalOrderPrice: totalSellPrice,
        washId,
      },
      {
        headers: {
          ...getV2AuthHeaders(headers),
        },
      }
    );
    yield put(productBuyNowSuccess());

    if (isNewAppVersion) {
      executeWeb2App({
        command: 'completeAlertCloseAll',
        values: {
          text: MESSAGES.PRODUCT.BUY_NOW_SUCCESS,
          tabName: 'Laundry',
        },
      });
    } else {
      completeAlertCloseAll(MESSAGES.PRODUCT.BUY_NOW_SUCCESS);
    }

    brazeEvent({
      eventName: 'item_order_completed',
      eventValue: {
        item_id: String(currentProduct.id),
        item_name: currentProduct.name,
        item_category: currentProduct.categoryName,
        item_price: `${totalSellPrice}`,
        item_tag: currentProduct.tags.join(','),
        item_image_url: currentProduct.representImgUrls[0],
        item_brand_name: currentProduct.brandName,
        total_number_of_item: totalQuantity,
        total_item_price: totalSellPrice,
      },
      purchase: {
        productId: 'store',
        price: totalSellPrice,
      },
    });
  } catch (error) {
    yield put(fetchProdcutFailure(error));
  } finally {
    hideLoading();
  }
}

export const sagas = [
  takeLatest<any>(FETCH_VALID_PRODUCT, watchFetchValidProductSaga),
  takeLatest<any>(FETCH_PRODUCT_DETAIL, watchFetchProductDetailSaga),
  takeLatest<any>(PRODUCT_OPTION_DELETE, watchProductOptionDeleteSaga),
  takeLatest<any>(PRODUCT_ADD_CART_REQUEST, watchFetchProductAddCartSaga),
  takeLatest<any>(PRODUCT_BUY_NOW_REQUEST, watchFetchProductBuyNowSaga),
];
